import React, { useState } from 'react';
import '../styles/TutorialScreen.css';
import TutorialScreen1 from '../assets/TutorialScreen1.webp';
import TutorialScreen2 from '../assets/TutorialScreen2.webp';
import TutorialScreen3 from '../assets/TutorialScreen3.webp';
import TutorialScreen4 from '../assets/TutorialScreen4.webp';
import TutorialScreen5 from '../assets/TutorialScreen5.webp';
import LeftButton from '../assets/Left_Button.webp';
import RightButton from '../assets/Right_Button.webp';
import GotItButton from '../assets/GotIt_Button.webp';
import OneOfFive from '../assets/1of5.webp';
import TwoOfFive from '../assets/2of5.webp';
import ThreeOfFive from '../assets/3of5.webp';
import FourOfFive from '../assets/4of5.webp';
import FiveOfFive from '../assets/5of5.webp';
import useHapticFeedback from '../hooks/useHapticFeedback';
import { useCurrency } from '../hooks/useCurrency';

const tutorialScreens = [
  { screen: TutorialScreen1, progress: OneOfFive },
  { screen: TutorialScreen2, progress: TwoOfFive },
  { screen: TutorialScreen3, progress: ThreeOfFive },
  { screen: TutorialScreen4, progress: FourOfFive },
  { screen: TutorialScreen5, progress: FiveOfFive }
];

const TutorialScreen = ({ onClose }) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const triggerHapticFeedback = useHapticFeedback('light');
  const { setTutorialSeen } = useCurrency(); // Import the setTutorialSeen function

  const handleNext = () => {
    triggerHapticFeedback();
    if (currentScreen < tutorialScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      setTutorialSeen(); // Set the tutorial as seen
      onClose();
    }
  };

  const handlePrev = () => {
    triggerHapticFeedback();
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleGotIt = () => {
    triggerHapticFeedback();
    setTutorialSeen(); // Set the tutorial as seen
    onClose();
  };

  return (
    <div className="tutorialScreenOverlay">
      <div className="tutorialScreenContainer">
        <img src={tutorialScreens[currentScreen].screen} className="tutorialScreenImage" alt={`Tutorial Screen ${currentScreen + 1}`} />
        <div className="navigationButtons">
          <img
            src={LeftButton}
            className={`navButton ${currentScreen === 0 ? 'disabled' : ''}`}
            alt="Left Button"
            onClick={currentScreen > 0 ? handlePrev : null}
          />
          <img
            src={tutorialScreens[currentScreen].progress}
            className="progressIndicator"
            alt={`Progress ${currentScreen + 1} of 5`}
          />
          <img
            src={RightButton}
            className={`navButton ${currentScreen === tutorialScreens.length - 1 ? 'disabled' : ''}`}
            alt="Right Button"
            onClick={handleNext}
          />
        </div>
        {currentScreen === tutorialScreens.length - 1 && (
          <img
            src={GotItButton}
            className="gotItButton"
            alt="Got It Button"
            onClick={handleGotIt}
          />
        )}
      </div>
    </div>
  );
};

export default TutorialScreen;
