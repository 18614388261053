// earningsCalculator.js

import { earningsPerUpgrade } from '../config/upgradeConfig';

export const calculateCumulativeEarnings = (upgradeType, level) => {
  const earningsArray = earningsPerUpgrade[upgradeType];
  if (!earningsArray) return 0;

  // If level is 1 or less, return 0 as there should be no earnings
  if (level <= 1) return 0;

  // Sum the earnings from level 1 to the current level - 1
  return earningsArray.slice(0, level - 1).reduce((sum, earning) => sum + earning, 0);
};