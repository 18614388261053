import React, { useState, useEffect, useCallback, useMemo } from 'react';
import UpgradeContext from '../contexts/UpgradeContext';
import { fetchUpgrades, saveUpgrade } from '../dataFetchers/upgradeFetcher.js';
import { useLoading } from '../contexts/LoadingContext.js';

export const UpgradeProvider = ({ children, userId }) => {
  const [upgradeLevels, setUpgradeLevels] = useState({});
  const { setProviderLoaded } = useLoading();

  // useMemo to prevent unnecessary recalculations and re-renders
  const loadUpgrades = useCallback(async () => {
    if (userId) {
      const upgrades = await fetchUpgrades(userId);
      const processedUpgrades = Object.fromEntries(
        Object.entries(upgrades).map(([key, value]) => [key, typeof value === 'object' ? value.level : value])
      );
      setUpgradeLevels(processedUpgrades);
      setProviderLoaded('upgrade');
    }
  }, [userId, setProviderLoaded]);

  // useEffect with the loadUpgrades function memoized by useCallback
  useEffect(() => {
    loadUpgrades();
  }, [loadUpgrades]);

  // Memoize the context value to prevent unnecessary re-renders of consumers
  const contextValue = useMemo(() => ({
    upgradeLevels,
    updateUpgradeLevel: async (upgradeType, level) => {
      setUpgradeLevels(prevLevels => ({
        ...prevLevels,
        [upgradeType]: level
      }));

      await saveUpgrade(userId, upgradeType, level);
    },
  }), [upgradeLevels, userId]);

  return (
    <UpgradeContext.Provider value={contextValue}>
      {children}
    </UpgradeContext.Provider>
  );
};

export default UpgradeProvider;
