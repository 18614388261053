// src/contexts/RegenContext.js
import { createContext, useContext } from 'react';

const RechargeContext = createContext();

export const useRecharge = () => {
  const context = useContext(RechargeContext);
  if (!context) {
    throw new Error('useRegen must be used within a RegenProvider');
  }
  return context;
};

export default RechargeContext;
