import PrayScreenBg from '../assets/PrayScreen.webp';
import DeepScreenBg from '../assets/DeepScreen.webp';
import LockedScreenBg from '../assets/LockedScreen.webp';
import PunchedScreenBg from '../assets/PunchedScreen.webp';
import RunScreenBg from '../assets/RunScreen.webp';
import WolfScreenBg from '../assets/WolfScreen.webp';
import JeetsScreenBg from '../assets/JeetsScreen.webp';
import CareerScreenBg from '../assets/CareerScreen.webp';
import HODLScreenBg from '../assets/HODLScreen.webp';
import PukeScreenBg from '../assets/PukeScreen.webp';
import TrainScreenBg from '../assets/TrainScreen.webp';
import PanicScreenBg from '../assets/PanicScreen.webp';
import RainScreenBg from '../assets/RainScreen.webp';
import StreetsScreenBg from '../assets/StreetsScreen.webp';
import FreezeScreenBg from '../assets/FreezeScreen.webp';
import BearScreenBg from '../assets/BearScreen.webp';
import TradeOfferScreenBg from '../assets/TradeOfferScreen.webp';
import PropositionScreenBg from '../assets/PropositionScreen.webp';
import NightScreenBg from '../assets/NightScreen.webp';
import GMScreenBg from '../assets/GMScreen.webp';
import WastedScreenBg from '../assets/WastedScreen.webp';
import NoSleepScreenBg from '../assets/NoSleepScreen.webp';
import GNScreenBg from '../assets/GNScreen.webp';
import LMAOScreenBg from '../assets/LMAOScreen.webp';
import ChannelScreenBg from '../assets/ChannelScreen.webp';
import ThinkerScreenBg from '../assets/ThinkerScreen.webp';

export const UPGRADE_TYPES = {
  PRAY: 'pray',
  DEEP: 'deep',
  LOCKED: 'locked',
  PUNCHED: 'punched',
  RUN: 'run',
  WOLF: 'wolf',
  JEETS: 'jeets',
  CAREER: 'career',
  HODL: 'hodl',
  PUKE: 'puke',
  TRAIN: 'train',
  PANIC: 'panic',
  RAIN: 'rain',
  STREETS: 'streets',
  FREEZE: 'freeze',
  BEAR: 'bear',
  TRADEOFFER: 'tradeoffer',
  PROPOSITION: 'proposition',
  NIGHT: 'night',
  GM: 'gm',
  WASTED: 'wasted',
  NOSLEEP: 'nosleep',
  GN: 'gn',
  LMAO: 'lmao',
  CHANNEL: 'channel',
  THINKER: 'thinker'
};

export const upgradeConfig = {
  [UPGRADE_TYPES.PRAY]: {
    title: "Pray with Beist",
    subtitle: "Upgrade Pray with Beist",
    background: PrayScreenBg,
    levelKey: 'prayLevel',
    updateLevelKey: 'updatePrayLevel',
  },
  [UPGRADE_TYPES.DEEP]: {
    title: "Deep Thinking",
    subtitle: "Upgrade Deep Thinking",
    background: DeepScreenBg,
    levelKey: 'deepLevel',
    updateLevelKey: 'updateDeepLevel',
  },
  [UPGRADE_TYPES.LOCKED]: {
    title: "Lock-In",
    subtitle: "Upgrade Lock-In",
    background: LockedScreenBg,
    levelKey: 'lockedLevel',
    updateLevelKey: 'updateLockedLevel',
  },
  [UPGRADE_TYPES.PUNCHED]: {
    title: "Punched by $BEIST",
    subtitle: "Upgrade Punched by $BEIST",
    background: PunchedScreenBg,
    levelKey: 'punchedLevel',
    updateLevelKey: 'updatePunchedLevel',
  },
  [UPGRADE_TYPES.RUN]: {
    title: "$BEIST Run",
    subtitle: "Upgrade $BEIST Run",
    background: RunScreenBg,
    levelKey: 'runLevel',
    updateLevelKey: 'updateRunLevel',
  },
  [UPGRADE_TYPES.WOLF]: {
    title: "$BEIST of Wall Street",
    subtitle: "Upgrade $BEIST of Wall Street",
    background: WolfScreenBg,
    levelKey: 'wolfLevel',
    updateLevelKey: 'updateWolfLevel',
  },
  [UPGRADE_TYPES.JEETS]: {
    title: "Destroy All Jeets",
    subtitle: "Upgrade Destroy All Jeets",
    background: JeetsScreenBg,
    levelKey: 'jeetsLevel',
    updateLevelKey: 'updateJeetsLevel',
  },
  [UPGRADE_TYPES.CAREER]: {
    title: "Career with $BEIST",
    subtitle: "Upgrade Career with $BEIST",
    background: CareerScreenBg,
    levelKey: 'careerLevel',
    updateLevelKey: 'updateCareerLevel',
  },
  [UPGRADE_TYPES.HODL]: {
    title: "HODL $BEIST",
    subtitle: "Upgrade HODL $BEIST",
    background: HODLScreenBg,
    levelKey: 'hodlLevel',
    updateLevelKey: 'updateHodlLevel',
  },
  [UPGRADE_TYPES.PUKE]: {
    title: "Sick Success",
    subtitle: "Upgrade Sick Success",
    background: PukeScreenBg,
    levelKey: 'pukeLevel',
    updateLevelKey: 'updatePukeLevel',
  },
  [UPGRADE_TYPES.TRAIN]: {
    title: "Train to Yeet",
    subtitle: "Upgrade Train to Yeet",
    background: TrainScreenBg,
    levelKey: 'trainLevel',
    updateLevelKey: 'updateTrainLevel',
  },
  [UPGRADE_TYPES.PANIC]: {
    title: "Panic Buy $BEIST",
    subtitle: "Upgrade Panic Buy $BEIST",
    background: PanicScreenBg,
    levelKey: 'panicLevel',
    updateLevelKey: 'updatePanicLevel',
  },
  [UPGRADE_TYPES.RAIN]: {
    title: "Raining $BEIST",
    subtitle: "Upgrade Raining $BEIST",
    background: RainScreenBg,
    levelKey: 'rainLevel',
    updateLevelKey: 'updateRainLevel',
  },
  [UPGRADE_TYPES.STREETS]: {
    title: "In the Streets",
    subtitle: "Upgrade In the Streets",
    background: StreetsScreenBg,
    levelKey: 'streetsLevel',
    updateLevelKey: 'updateStreetsLevel',
  },
  [UPGRADE_TYPES.FREEZE]: {
    title: "Freeze You Poor Bastard",
    subtitle: "Upgrade Freeze You Poor Bastard",
    background: FreezeScreenBg,
    levelKey: 'freezeLevel',
    updateLevelKey: 'updateFreezeLevel',
  },
  [UPGRADE_TYPES.BEAR]: {
    title: "Bear Market Survivalist",
    subtitle: "Upgrade Bear Market Survivalist",
    background: BearScreenBg,
    levelKey: 'bearLevel',
    updateLevelKey: 'updateBearLevel',
  },
  [UPGRADE_TYPES.TRADEOFFER]: {
    title: "Degenerate Trade Offer",
    subtitle: "Upgrade Degenerate Trade Offer",
    background: TradeOfferScreenBg,
    levelKey: 'tradeOfferLevel',
    updateLevelKey: 'updateTradeOfferLevel',
  },
  [UPGRADE_TYPES.PROPOSITION]: {
    title: "An Interesting Proposal",
    subtitle: "Upgrade An Interesting Proposal",
    background: PropositionScreenBg,
    levelKey: 'propositionLevel',
    updateLevelKey: 'updatePropositionLevel',
  },
  [UPGRADE_TYPES.NIGHT]: {
    title: "Late night Gambling",
    subtitle: "Upgrade Late night Gambling",
    background: NightScreenBg,
    levelKey: 'nightLevel',
    updateLevelKey: 'updateNightLevel',
  },
  [UPGRADE_TYPES.GM]: {
    title: "GM Streak",
    subtitle: "Upgrade GM Streak",
    background: GMScreenBg,
    levelKey: 'gmLevel',
    updateLevelKey: 'updateGmLevel',
  },
  [UPGRADE_TYPES.WASTED]: {
    title: "Wasted",
    subtitle: "Upgrade Wasted",
    background: WastedScreenBg,
    levelKey: 'wastedLevel',
    updateLevelKey: 'updateWastedLevel',
  },
  [UPGRADE_TYPES.NOSLEEP]: {
    title: "Don't Sleep on $BEIST",
    subtitle: "Upgrade Don't Sleep on $BEIST",
    background: NoSleepScreenBg,
    levelKey: 'noSleepLevel',
    updateLevelKey: 'updateNoSleepLevel',
  },
  [UPGRADE_TYPES.GN]: {
    title: "Say GN",
    subtitle: "Upgrade Say GN",
    background: GNScreenBg,
    levelKey: 'gnLevel',
    updateLevelKey: 'updateGnLevel',
  },
  [UPGRADE_TYPES.LMAO]: {
    title: "Ignore your problems",
    subtitle: "Upgrade Ignore your problems",
    background: LMAOScreenBg,
    levelKey: 'lmaoLevel',
    updateLevelKey: 'updateLmaoLevel',
  },
  [UPGRADE_TYPES.CHANNEL]: {
    title: "Start a Channel",
    subtitle: "Upgrade Start a Channel",
    background: ChannelScreenBg,
    levelKey: 'channelLevel',
    updateLevelKey: 'updateChannelLevel',
  },
  [UPGRADE_TYPES.THINKER]: {
    title: "Great Minds Think Alike",
    subtitle: "Upgrade Great Minds Think Alike",
    background: ThinkerScreenBg,
    levelKey: 'thinkerLevel',
    updateLevelKey: 'updateThinkerLevel',
  },
};
export const upgradeCosts = {
    pray: [500, 552, 638, 776, 990, 1327, 1778, 2382, 3192, 4277, 5731, 7679, 10289, 13786, 18472, 24750, 33163, 44435, 59539, 79776],
    deep: [4200, 4634, 5361, 6517, 8319, 11147, 14936, 20013, 26815, 35929, 48142, 64505, 86430, 115808, 155171, 207914, 278584, 373275, 500151, 670153],
    locked: [1200, 1324, 1532, 1862, 2377, 3185, 4267, 5718, 7661, 10265, 13754, 18429, 24693, 33086, 44332, 59401, 79591, 106645, 142893, 191463],
    punched: [2500, 2759, 3191, 3879, 4952, 6635, 8890, 11912, 15960, 21385, 28654, 38394, 51444, 68930, 92359, 123752, 165816, 222176, 297694, 398880],
    run: [3830, 4226, 4889, 5943, 7586, 10165, 13619, 18249, 24451, 32763, 43899, 58820, 78812, 105601, 141494, 189588, 254030, 340374, 456067, 611085],
    wolf: [5800, 6400, 7404, 8999, 11488, 15393, 20625, 27635, 37028, 49614, 66478, 89074, 119350, 159918, 214274, 287105, 384692, 515449, 690650, 925402],
    jeets: [600, 662, 766, 931, 1188, 1592, 2134, 2859, 3831, 5133, 6877, 9215, 12347, 16543, 22166, 29701, 39796, 53322, 71447, 95731],
    career: [900, 993, 1149, 1396, 1783, 2389, 3200, 4288, 5746, 7699, 10316, 13822, 18520, 24815, 33249, 44551, 59694, 79983, 107170, 143597],
    hodl: [2000, 2207, 2553, 3103, 3961, 5308, 7112, 9529, 12768, 17108, 22924, 30715, 41155, 55144, 73887, 99002, 132652, 177741, 238155, 319104],
    puke: [5000, 5517, 6382, 7758, 9903, 13270, 17780, 23823, 31921, 42771, 57309, 76788, 102888, 137860, 184719, 247504, 331631, 444353, 595388, 797761],
    train: [2100, 2317, 2681, 3258, 4159, 5573, 7468, 10006, 13407, 17964, 24070, 32251, 43213, 57901, 77582, 103952, 139285, 186628, 250063, 335059],
    panic: [400, 441, 511, 621, 792, 1062, 1422, 1906, 2554, 3422, 4585, 6143, 8231, 11029, 14777, 19800, 26530, 35548, 47631, 63821],
    rain: [1000, 1103, 1276, 1552, 1981, 2654, 3556, 4765, 6384, 8554, 11462, 15358, 20578, 27572, 36944, 49501, 66326, 88871, 119078, 159552],
    streets: [3000, 3310, 3829, 4655, 5942, 7962, 10668, 14294, 19153, 25663, 34385, 46073, 61733, 82716, 110831, 148503, 198979, 266612, 357233, 478656],
    freeze: [5900, 6510, 7531, 9154, 11686, 15658, 20980, 28112, 37667, 50470, 67624, 90610, 121408, 162675, 217968, 292055, 391325, 524336, 702558, 941358],
    bear: [700, 772, 894, 1086, 1386, 1858, 2489, 3335, 4469, 5988, 8023, 10750, 14404, 19300, 25861, 34651, 46428, 62209, 83354, 111686],
    tradeoffer: [600, 662, 766, 931, 1188, 1592, 2134, 2859, 3831, 5133, 6877, 9215, 12347, 16543, 22166, 29701, 39796, 53322, 71447, 95731],
    proposition: [1000, 1103, 1276, 1552, 1981, 2654, 3556, 4765, 6384, 8554, 11462, 15358, 20578, 27572, 36944, 49501, 66326, 88871, 119078, 159552],
    night: [2000, 2207, 2553, 3103, 3961, 5308, 7112, 9529, 12768, 17108, 22924, 30715, 41155, 55144, 73887, 99002, 132652, 177741, 238155, 319104],
    gm: [2500, 2759, 3191, 3879, 4952, 6635, 8890, 11912, 15960, 21385, 28654, 38394, 51444, 68930, 92359, 123752, 165816, 222176, 297694, 398880],
    wasted: [700, 772, 894, 1086, 1386, 1858, 2489, 3335, 4469, 5988, 8023, 10750, 14404, 19300, 25861, 34651, 46428, 62209, 83354, 111686],
    nosleep: [4000, 4414, 5106, 6206, 7923, 10616, 14224, 19059, 25537, 34217, 45847, 61430, 82311, 110288, 147775, 198004, 265305, 355482, 476311, 638208],
    gn: [500, 552, 638, 776, 990, 1327, 1778, 2382, 3192, 4277, 5731, 7679, 10289, 13786, 18472, 24750, 33163, 44435, 59539, 79776],
    lmao: [200, 221, 255, 310, 396, 531, 711, 953, 1277, 1711, 2292, 3072, 4116, 5514, 7389, 9900, 13265, 17774, 23816, 31910],
    channel: [3000, 3310, 3829, 4655, 5942, 7962, 10668, 14294, 19153, 25663, 34385, 46073, 61733, 82716, 110831, 148503, 198979, 266612, 357233, 478656],
    thinker: [5600, 6179, 7148, 8689, 11092, 14862, 19914, 26682, 35752, 47903, 64186, 86003, 115235, 154403, 206885, 277205, 371427, 497675, 666835, 893492]
  };
  
  export const earningsPerUpgrade = {
    pray: [70, 75, 80, 86, 92, 98, 105, 112, 120, 129, 138, 147, 158, 169, 180, 193, 206, 221, 236, 253],
    deep: [420, 449, 481, 513, 551, 588, 630, 672, 720, 774, 828, 882, 948, 1014, 1080, 1158, 1239, 1325, 1415, 1518],
    locked: [110, 118, 126, 134, 144, 154, 165, 176, 189, 203, 217, 231, 248, 266, 283, 303, 324, 347, 371, 398],
    punched: [270, 289, 309, 330, 354, 378, 405, 432, 463, 497, 532, 567, 609, 652, 694, 744, 796, 852, 910, 976],
    run: [1800, 1926, 2060, 2200, 2360, 2520, 2700, 2880, 3085, 3317, 3548, 3779, 4063, 4346, 4629, 4962, 5309, 5678, 6064, 6505],
    wolf: [400, 428, 458, 489, 524, 560, 600, 640, 686, 737, 788, 840, 903, 966, 1029, 1103, 1180, 1262, 1348, 1446],
    jeets: [70, 75, 80, 86, 92, 98, 105, 112, 120, 129, 138, 147, 158, 169, 180, 193, 206, 221, 236, 253],
    career: [90, 96, 103, 110, 118, 126, 135, 144, 154, 166, 177, 189, 203, 217, 231, 248, 265, 284, 303, 325],
    hodl: [240, 257, 275, 293, 315, 336, 360, 384, 411, 442, 473, 504, 542, 579, 617, 662, 708, 757, 809, 867],
    puke: [900, 963, 1030, 1100, 1180, 1260, 1350, 1440, 1543, 1658, 1774, 1890, 2031, 2173, 2314, 2481, 2655, 2839, 3032, 3253],
    train: [180, 193, 206, 220, 236, 252, 270, 288, 309, 332, 355, 378, 406, 435, 463, 496, 531, 568, 606, 651],
    panic: [40, 43, 46, 49, 52, 56, 60, 64, 69, 74, 79, 84, 90, 97, 103, 110, 118, 126, 135, 145],
    rain: [70, 77, 85, 94, 103, 113, 124, 136, 149, 162, 177, 192, 209, 226, 244, 263, 283, 304, 326, 350],
    streets: [210, 232, 255, 280, 306, 334, 363, 394, 427, 461, 497, 535, 575, 617, 661, 707, 755, 806, 859, 915],
    freeze: [420, 465, 512, 561, 612, 666, 722, 780, 841, 904, 970, 1038, 1109, 1183, 1260, 1339, 1421, 1506, 1594, 1685],
    bear: [50, 55, 60, 65, 70, 75, 81, 87, 93, 99, 106, 113, 120, 128, 136, 144, 153, 162, 171, 181],
    tradeoffer: [70, 77, 85, 94, 103, 113, 124, 136, 149, 162, 177, 192, 209, 226, 244, 263, 283, 304, 326, 350],
    proposition: [140, 154, 170, 188, 206, 226, 248, 272, 298, 326, 356, 388, 422, 458, 497, 538, 582, 628, 677, 729],
    night: [200, 220, 242, 266, 292, 320, 350, 383, 417, 454, 493, 535, 579, 625, 674, 726, 781, 839, 900, 964],
    gm: [150, 165, 181, 198, 217, 236, 258, 280, 304, 330, 357, 386, 416, 448, 482, 518, 555, 594, 635, 678],
    wasted: [50, 55, 60, 65, 70, 75, 81, 87, 93, 99, 106, 113, 120, 128, 136, 144, 153, 162, 171, 181],
    nosleep: [280, 309, 339, 372, 407, 444, 484, 526, 570, 617, 666, 718, 772, 829, 889, 952, 1017, 1086, 1158, 1233],
    gn: [35, 39, 45, 51, 58, 65, 73, 82, 91, 101, 112, 124, 137, 151, 166, 182, 200, 219, 239, 261],
    lmao: [14, 16, 18, 20, 22, 25, 27, 30, 33, 36, 39, 42, 46, 49, 53, 57, 61, 66, 70, 75],
    channel: [210, 232, 255, 280, 306, 334, 363, 394, 427, 461, 497, 535, 575, 617, 661, 707, 755, 806, 859, 915],
    thinker: [390, 432, 476, 522, 571, 623, 677, 733, 792, 854, 918, 985, 1055, 1128, 1204, 1283, 1365, 1450, 1538, 1630]
  };