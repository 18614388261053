import React, { useState, useCallback, useMemo } from 'react';
import LoadingContext from '../contexts/LoadingContext';

export const LoadingProvider = ({ children }) => {
  const [loadingStates, setLoadingStates] = useState({
    currency: true,
    dailyRewards: true,
    earn: true,
    recharge: true,
  });

  const setProviderLoaded = useCallback((providerName) => {
    console.log(`Provider ${providerName} loaded`);
    setLoadingStates(prev => {
      const newState = { ...prev, [providerName]: false };
      console.log('Current loading states:', newState);
      return newState;
    });
  }, []);

  const contextValue = useMemo(() => {
    const isLoading = Object.values(loadingStates).some(state => state);
    return { loadingStates, setProviderLoaded, isLoading };
  }, [loadingStates, setProviderLoaded]);

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};