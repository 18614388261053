import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EarnContext from '../contexts/EarnContext';
import { saveButtonStateToDB, fetchXData } from '../services/firebaseService';
import { useCurrency } from '../contexts/CurrencyContext';
import { useLoading } from '../contexts/LoadingContext';
import { useTime } from '../contexts/TimeContext';

export const EarnProvider = ({ children, userId }) => {
  const { addCurrency } = useCurrency();
  const [followedX, setFollowedX] = useState(false);
  const [followButtonClicked, setFollowButtonClicked] = useState(false);
  const { setProviderLoaded } = useLoading();
  const { getAdjustedTime } = useTime();

  useEffect(() => {
    const initialize = async () => {
      if (!userId) {
        setProviderLoaded('earn');
        return;
      }

      const userData = await fetchXData(userId);

      if (userData && userData.xProfile) {
        setFollowedX(userData.xProfile.followedX || false);
        setFollowButtonClicked(userData.xProfile.followButtonClicked || false);
      }

      setProviderLoaded('earn');
    };
    
    initialize();
  }, [userId, setProviderLoaded]);

  const handleFollowButtonClick = useCallback(async () => {
    setFollowButtonClicked(true);
    await saveButtonStateToDB(userId, { 'xProfile/followButtonClicked': true }, getAdjustedTime);
    window.open('https://x.com/MrBeist6000', '_blank');
  }, [userId, getAdjustedTime]);

  const handleClaimReward = useCallback(async () => {
    if (!userId || !followButtonClicked || followedX) {
      return;
    }

    try {
      await saveButtonStateToDB(userId, { 'xProfile/followedX': true }, getAdjustedTime);
      setFollowedX(true);
      addCurrency(5000);
    } catch (error) {
      console.error('Error claiming X follow reward:', error);
    }
  }, [followButtonClicked, followedX, userId, addCurrency, getAdjustedTime]);

  const contextValue = useMemo(() => ({
    userId,
    followedX,
    handleFollowButtonClick,
    handleClaimReward,
    followButtonClicked
  }), [
    userId,
    followedX,
    handleFollowButtonClick,
    handleClaimReward,
    followButtonClicked
  ]);

  return (
    <EarnContext.Provider value={contextValue}>
      {children}
    </EarnContext.Provider>
  );
};

export default EarnProvider;