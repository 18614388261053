import React, { useEffect, useState } from 'react';
import { getServerTime } from '../services/serverTime';
import TimeContext from '../contexts/TimeContext';

export const TimeProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [timeOffset, setTimeOffset] = useState(0);

  useEffect(() => {
    const initializeTime = async () => {
      const serverTime = await getServerTime();  // Fetches server time when the app starts
      setTimeOffset(serverTime - Date.now());    // Calculate and store the time offset
      setIsInitialized(true);                    // Set the initialization flag
    };

    initializeTime();  // Call the function to fetch server time and initialize the app's time state

    const syncInterval = setInterval(async () => {
      const serverTime = await getServerTime();  // Periodically sync the server time every 5 minutes
      setTimeOffset(serverTime - Date.now());
    }, 5 * 60 * 1000);  // 5 minutes interval

    return () => clearInterval(syncInterval);  // Cleanup interval on unmount
  }, []);

  const getAdjustedTime = () => {
    return Date.now() + timeOffset;  // Returns the adjusted time using the stored offset
  };

  return (
    <TimeContext.Provider value={{ getAdjustedTime, isInitialized }}>
      {children}
    </TimeContext.Provider>
  );
};
