// src/hooks/useHapticFeedback.js

import { useCallback, useContext } from 'react';
import CurrencyContext from '../contexts/CurrencyContext';

const useHapticFeedback = (intensity = 'light') => {
  const { hapticFeedback } = useContext(CurrencyContext);

  const triggerHapticFeedback = useCallback(() => {
    if (hapticFeedback && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred(intensity);
    }
  }, [intensity, hapticFeedback]);

  return triggerHapticFeedback;
};

export default useHapticFeedback;
