// src/utils/preloadImages.js
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

preloadImages([
  require('../assets/Turbo_Button1.webp'),
  require('../assets/Multitap_Button.webp'),
  require('../assets/Maxenergy_Button.webp'),
  require('../assets/Recharger_Button.webp'),
  require('../assets/Settings_Button.webp'),
  require('../assets/Profile_Button.webp'),
  require('../assets/EarnPerTap_Button.webp'),
  require('../assets/MoneyUntilLevel_Button.webp'),
  require('../assets/MoneyPerHour_Button.webp'),
  require('../assets/Money_Logo.webp'),
  require('../assets/Logo.webp'),
  require('../assets/Header_Bar.webp'),
  require('../assets/Logo_Icon.webp'),
  require('../assets/Energy_Icon.webp'),
  require('../assets/Background.webp'),
  require('../assets/RechargeScreen.webp'),
  require('../assets/Close_Button.webp'),
  require('../assets/InsufficientMoney_Button.webp'),
  require('../assets/Recharge_Button.webp'),
  require('../assets/Boost_Icon.webp'),
  require('../assets/Token_Logo.webp'),
  require('../assets/Footer_Bar.webp'),
  require('../assets/Home_Button.webp'),
  require('../assets/Mine_Button.webp'),
  require('../assets/Earn_Button.webp'),
  require('../assets/Airdrop_Button.webp'),
  require('../assets/bar1.webp'),
  require('../assets/bar2.webp'),
]);

export default preloadImages;
