// WalletScreen.js
import React, { useEffect, useRef, useState } from 'react';
import '../styles/WalletScreen.css';
import CloseButton from '../assets/Close_Button.webp';
import ButtonImage from '../assets/Button.webp'; // Import the button image
import useHapticFeedback from '../hooks/useHapticFeedback';

const WalletScreen = ({ onClose }) => {
  const screenRef = useRef(null);
  const [walletAddress, setWalletAddress] = useState('');
  const triggerHapticFeedback = useHapticFeedback('light');

  const handleClickOutside = (event) => {
    if (screenRef.current && !screenRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleWalletChange = (event) => {
    setWalletAddress(event.target.value);
  };

  return (
    <div className="walletScreenOverlay">
      <div className="walletScreenContainer" ref={screenRef} style={{ opacity: 0, transition: 'opacity 0.5s ease-in-out' }} onLoad={() => screenRef.current.style.opacity = 1}>
        <img src={CloseButton} className="walletCloseButton" alt="Close" onClick={onClose} />
        <div className="walletScreenContent">
          <h2 className="walletTitle">Link Account to SOL</h2>
          <div className="walletInputContainer">
            <img src={ButtonImage} className="walletInputButton" alt="Wallet Input" />
            <input 
              type="text" 
              className="walletInputField" 
              value={walletAddress} 
              onChange={handleWalletChange} 
              placeholder="Enter Solana Wallet Address" 
            />
          </div>
          <div className="connectButtonContainer" onClick={triggerHapticFeedback}>
            <img src={ButtonImage} className="connectButton" alt="Connect Button" />
            <span className="connectButtonText">Connect</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletScreen;
