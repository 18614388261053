import React, { useEffect } from 'react';
import AirdropLogo from '../assets/Loading_Logo.webp';
import GreenCircle from '../assets/GreenCircle.webp';
import '../styles/LoadingScreen.css';

const NewLoadingScreen = () => {
  useEffect(() => {
    document.body.classList.add('new-loading');
    return () => {
      document.body.classList.remove('new-loading');
    };
  }, []);

  return (
    <div className="new-loading-screen">
      <div className="new-loading-content">
        <div className="new-airdrop-container">
          <img src={AirdropLogo} className="new-logo-airdrop" alt="Logo Airdrop" />
        </div>
        <div className="new-airdrop-title-text">Loading...</div>
        <div className="new-text-container">
          <div className="new-text-line">
            <img src={GreenCircle} className="new-circle-image" alt="Green Circle" />
            <span>Initializing...</span>
          </div>
          <div className="new-text-line">
            <img src={GreenCircle} className="new-circle-image" alt="Green Circle" />
            <span>Fetching Data...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLoadingScreen;
