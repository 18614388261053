import { db } from '../firebase';
import { ref, get, update, runTransaction } from 'firebase/database';
import { calculateTotalEarningsPerHour } from '../utils/upgradeCalculator';

export const fetchUserData = async (
  userId,
  setUser,
  setCurrency,
  setEnergy,
  setCurrentLevel,
  setClickedButtons,
  setMultitapLevel,
  setMaxEnergyLevel,
  setLastUpdated,
  setHapticFeedback,
  setJoinedTelegram,
  setOfflineEarnings,
  setHasSeenTutorial,
  setInvitedFriends,
  setReferredUser,
  setTotalEarningsPerHour,
  getAdjustedTime
) => {
  try {
    const userRef = ref(db, `users/${userId}`);
    const userSnap = await get(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.val();
      const now = getAdjustedTime();
      const lastUpdated = userData.stats?.lastUpdated ?? now;

      const timeDifferenceInSeconds = Math.floor((now - lastUpdated) / 1000);
      const cappedTimeDifference = Math.min(timeDifferenceInSeconds, 10800);

      const currentLevel = Math.max(userData.stats?.currentLevel ?? 1, userData.stats?.highestLevel ?? 1);
      const maxEnergyLevel = userData.upgrades?.maxEnergyLevel ?? 1;
      const maxEnergy = Math.floor(1000 + ((currentLevel - 1) * 500) + ((maxEnergyLevel - 1) * 500));

      const totalEarningsPerHour = calculateTotalEarningsPerHour(userData.upgrades, currentLevel);

      const energyRegenPerSecond = 3;
      const regeneratedEnergy = Math.floor(timeDifferenceInSeconds * energyRegenPerSecond);

      const earningsPerSecond = totalEarningsPerHour / 3600;
      const passiveIncome = Math.floor(cappedTimeDifference * earningsPerSecond);

      const newCurrency = (userData.stats?.currency || 0) + passiveIncome;
      const newEnergy = Math.min((userData.stats?.energy ?? 0) + regeneratedEnergy, maxEnergy);

      // Update the database with the new calculated values
      const updates = {
        'stats/energy': newEnergy,
        'stats/currency': newCurrency,
        'stats/lastUpdated': now,
        'stats/maxEnergy': maxEnergy,  // Ensure this is updated
        'stats/totalEarningsPerHour': totalEarningsPerHour,
        'stats/currentLevel': currentLevel,
        'stats/highestLevel': currentLevel
      };

      await update(userRef, updates);

      if (userData.referral?.referrerId) {
        await saveReferredUserIncome(userId, passiveIncome);
      }

      const updatedUserData = {
        ...userData,
        stats: {
          ...userData.stats,
          ...updates  // Apply all updates to the stats
        }
      };

      setUser(updatedUserData);
      setCurrency(Math.max(0, newCurrency));
      setEnergy(Math.max(0, newEnergy));
      setCurrentLevel(currentLevel);
      setClickedButtons(userData.stats?.clickedButtons ?? []);
      setMultitapLevel(userData.upgrades?.multitapLevel ?? 1);
      setMaxEnergyLevel(maxEnergyLevel);  // Ensure maxEnergyLevel is set in the state
      setLastUpdated(now);
      setHapticFeedback(userData.settings?.hapticFeedback ?? true);
      setJoinedTelegram(userData.settings?.joinedTelegram ?? false);
      setOfflineEarnings(passiveIncome);
      setHasSeenTutorial(userData.settings?.hasSeenTutorial ?? false);
      setInvitedFriends(userData.settings?.invitedFriends ?? false);
      setReferredUser(userData.referral?.referredUser ?? 0);
      setTotalEarningsPerHour(totalEarningsPerHour);

      return updatedUserData;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};


const sanitizeValue = (value) => {
  if (typeof value === 'number' && isNaN(value)) return 0;
  if (typeof value === 'undefined') return null;
  return value;
};

export const saveButtonStateToDB = async (userId, newState, getAdjustedTime) => {
  if (!userId) return;

  try {
    const updates = {};
    let shouldUpdateLastUpdated = false;

    for (const [key, value] of Object.entries(newState)) {
      const sanitizedValue = sanitizeValue(value);
      console.log(`Processing key: ${key}, value: ${sanitizedValue}`);

      if (key.includes('/')) {
        // Handle nested paths
        updates[key] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else if (['currency', 'clickedButtons', 'offlineEarnings', 'maxEnergy'].includes(key)) {
        updates[`stats/${key}`] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else if (key === 'energy' && sanitizedValue !== 1000) {
        updates[`stats/${key}`] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else if (['multitapLevel', 'maxEnergyLevel'].includes(key)) {
        updates[`upgrades/${key}`] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else if (['hasSeenTutorial', 'hapticFeedback', 'joinedTelegram', 'invitedFriends'].includes(key)) {
        updates[`settings/${key}`] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else if (['inviteToken', 'referredUser', 'referrerId', 'referredUsers'].includes(key)) {
        updates[`referral/${key}`] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      } else {
        updates[key] = sanitizedValue;
        shouldUpdateLastUpdated = true;
      }
    }

    if (shouldUpdateLastUpdated) {
      updates['stats/lastUpdated'] = getAdjustedTime();
    }

    const userRef = ref(db, `users/${userId}`);
    await update(userRef, updates);
    console.log("Button state saved successfully.");
  } catch (error) {
    console.error('Error saving button state:', error);
    throw error;
  }
};




export const saveReferredUserIncome = async (referredUserId, amount) => {
  try {
    const referredUserRef = ref(db, `users/${referredUserId}`);
    const referredUserSnap = await get(referredUserRef);
    if (referredUserSnap.exists()) {
      const referredUserData = referredUserSnap.val();
      const referrerId = referredUserData.referral?.referrerId;
      const referredUserFirstName = referredUserData.profile?.first_name || 'Unknown';
      if (referrerId) {
        const referrerRef = ref(db, `users/${referrerId}`);
        await runTransaction(referrerRef, (userData) => {
          if (userData) {
            if (!userData.referredUserGeneratedIncome) {
              userData.referredUserGeneratedIncome = {};
            }
            if (!userData.referredUserGeneratedIncome[referredUserId]) {
              userData.referredUserGeneratedIncome[referredUserId] = {
                generatedIncome: 0,
                first_name: referredUserFirstName
              };
            }
            // Add only 16% of the amount
            userData.referredUserGeneratedIncome[referredUserId].generatedIncome += amount * 0.16;
          }
          return userData;
        });
        console.log(`Tracked generated income for referred user ${referredUserId}: ${amount * 0.16}`);
      } else {
        console.error(`Referrer ID not found for referred user ${referredUserId}`);
      }
    }
  } catch (error) {
    console.error('Error tracking referred user income:', error);
  }
};

export const fetchXData = async (userId) => {
  try {
    const userRef = ref(db, `users/${userId}`);
    const userSnap = await get(userRef);
    if (userSnap.exists()) {
      const userData = userSnap.val();

      return { 
        followedX: userData.followedX, 
        xProfile: userData.xProfile 
      };
    }
  } catch (error) {
    console.error('Error fetching X data:', error);
  }

  return null;
};

