// src/contexts/LoadingContext.js
import { createContext, useContext } from 'react';

const LoadingContext = createContext({
  loadingStates: {},
  setProviderLoaded: () => {},
  isLoading: true,
});

export const useLoading = () => useContext(LoadingContext);

export default LoadingContext;