import React, { useMemo } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/MainLayout.css'; // Create and import a new CSS file for MainLayout-specific styles
import BackgroundImage from '../assets/Background.webp';

const MainLayout = ({ children }) => {
  const memoizedHeader = useMemo(() => <Header />, []);
  const memoizedFooter = useMemo(() => <Footer />, []);

  return (
    <div className="main-layout" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {memoizedHeader}
      <div className="content">{children}</div>
      {memoizedFooter}
    </div>
  );
};

export default MainLayout;
