import { db } from '../firebase';
import { ref, get, update } from 'firebase/database';

export const fetchDailyRewardsData = async (userId, getAdjustedTime) => {
  try {
    const userRef = ref(db, `users/${userId}`);
    const userSnap = await get(userRef);
    const serverTime = getAdjustedTime();
    
    if (userSnap.exists()) {
      const userData = userSnap.val();
      if (userData.dailyRewards) {
        const { currentDay, lastClaimed } = userData.dailyRewards;
        return {
          currentDay: currentDay || 1,
          lastClaimed: lastClaimed || null,
          serverTime
        };
      }
    }
    return {
      currentDay: 1,
      lastClaimed: null,
      serverTime
    };
  } catch (error) {
    console.error('Error fetching daily rewards data:', error);
    throw error;
  }
};

export const saveDailyRewardsData = async (userId, dailyRewardsState, getAdjustedTime) => {
  if (userId) {
    try {
      const updates = {};
      const serverTime = getAdjustedTime();

      for (const [key, value] of Object.entries(dailyRewardsState)) {
        if (['currentDay', 'lastClaimed'].includes(key)) {
          updates[`dailyRewards/${key}`] = value;
        }
      }
      updates['stats/lastUpdated'] = serverTime;

      const userRef = ref(db, `users/${userId}`);
      await update(userRef, updates);
      console.log("Daily rewards data saved successfully.");
    } catch (error) {
      console.error('Error saving daily rewards data:', error);
      throw error;
    }
  }
};