import { UPGRADE_TYPES } from '../config/upgradeConfig';
import { calculateCumulativeEarnings } from './earningsCalculator';

export const calculateTotalEarningsPerHour = (upgrades, currentLevel) => {
    console.log('Calculating total earnings with upgrades:', upgrades, 'and currentLevel:', currentLevel);
  
    const baseEarnings = 10 * Math.pow(2, currentLevel - 1);
    console.log('Base earnings:', baseEarnings);
  
    let upgradeEarnings = 0;
  
    for (const [upgradeKey, upgradeInfo] of Object.entries(UPGRADE_TYPES)) {
      const level = upgrades[`${upgradeKey.toLowerCase()}Level`] || 1;
      const earnings = calculateCumulativeEarnings(upgradeInfo, level);
      console.log(`Earnings for ${upgradeKey}:`, earnings);
      upgradeEarnings += earnings;
    }
  
    const total = baseEarnings + upgradeEarnings;
    console.log('Total earnings per hour:', total);
  
    if (isNaN(total)) {
      console.error('NaN detected in total earnings calculation');
      console.log('Debug info:', {
        upgrades,
        currentLevel,
        baseEarnings,
        upgradeEarnings
      });
      throw new Error('NaN detected in total earnings calculation');
    }
  
    return total;
  };