// DailyRewardsContext.js
import React, { createContext, useContext } from 'react';

const DailyRewardsContext = createContext();

export const useDailyRewards = () => {
  const context = useContext(DailyRewardsContext);
  if (!context) {
    throw new Error('useDailyRewards must be used within a DailyRewardsProvider');
  }
  return context;
};

export default DailyRewardsContext;  // Add this line for default export

