// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, get, serverTimestamp } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHJ1B6BkM4SL6pcONxfODQxVTp8hkxNxc",
  authDomain: "test-dfa51.firebaseapp.com",
  databaseURL: "https://test-dfa51-default-rtdb.firebaseio.com",
  projectId: "test-dfa51",
  storageBucket: "test-dfa51.appspot.com",
  messagingSenderId: "698397610574",
  appId: "1:698397610574:web:25a5a2c21406855861691f",
  measurementId: "G-LCWWNVZ5QT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);

export { db, serverTimestamp };

export const getServerTime = async () => {
  try {
    const timeRef = ref(db, '.info/serverTimeOffset');
    const snapshot = await get(timeRef);
    const offset = snapshot.val() || 0;
    return Date.now() + offset;
  } catch (error) {
    console.error('Error fetching server time:', error);
    return Date.now(); // Fallback to client time
  }
};