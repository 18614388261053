// src/services/serverTime.js

let callCount = 0;

export const getServerTime = async () => {
  try {
    const response = await fetch('/api/getServerTime');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Oops! We haven't received a JSON response");
    }
    const data = await response.json();
    return data.serverTime;
  } catch (error) {
    console.error('Error fetching server time:', error);
    return Date.now(); // Fallback to client time
  }
};