import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { saveAppState } from './utils/saveAppState';

import QRCode from './assets/QRCode.webp';

function isSmartphone() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isAndroid = /android/i.test(userAgent);
  const isIPhone = /iPhone|iPod/.test(userAgent) && !window.MSStream;

  // Check if it's an iPad in iOS 13+ (Safari no longer distinguishes iPads as tablets)
  const isIPad = /iPad/.test(userAgent) || (isIPhone && (window.innerWidth >= 768 && window.innerHeight >= 1024));

  return (isAndroid || isIPhone) && !isIPad;
}

function Main() {
  const [isSmartphoneDevice, setIsSmartphoneDevice] = useState(true);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    if (!isSmartphone()) {
      setIsSmartphoneDevice(false);
    }

    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    const handleTouchStart = (event) => {
      if (event.touches.length > 2) {
        event.preventDefault();
      }
    };

    const handleTouchMove = (event) => {
      const scrollable = event.target.closest('.scrollableInvestContainer, .scrollableNewAccountsContainer');
      if (!scrollable) {
        event.preventDefault();
      }
    };

    window.addEventListener('resize', handleOrientationChange);
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    handleOrientationChange(); // Initial check

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  if (!isSmartphoneDevice) {
    return (
      <div className="qr-code-container">
        <h1>Play on your mobile</h1>
        <img src={QRCode} alt="QR Code" className="qr-code-image" />
      </div>
    );
  }

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);

window.addEventListener("beforeunload", () => {
  saveAppState(); // Call your state saving function
});

reportWebVitals();
