import React from 'react';
import { Link } from 'react-router-dom';
import FooterBar from '../assets/Footer_Bar.webp';
import HomeButton from '../assets/Home_Button.webp';
import MineButton from '../assets/Mine_Button.webp';
import EarnButton from '../assets/Earn_Button.webp';
import AirdropButton from '../assets/Airdrop_Button.webp';
import FriendButton from '../assets/Friend_Button.webp'; // Import the new Friend Button
import '../styles/Footer.css';
import useHapticFeedback from '../hooks/useHapticFeedback';
import { useCurrency } from '../contexts/CurrencyContext';

const Footer = () => {
  const triggerHapticFeedback = useHapticFeedback('light');
  const { loading } = useCurrency(); // Get the loading state from useCurrency

  if (loading) {
    return null; // Hide the footer while loading
  }

  return (
    <div>
      <img src={FooterBar} className="footerBar" alt="Footer Bar" />
      <div className="footerButtonsContainer">
        <Link to="/" className="footerButton" onClick={triggerHapticFeedback}>
          <img src={HomeButton} className="buttonBackground" alt="Home Button" />
        </Link>
        <Link to="/invest" className="footerButton" onClick={triggerHapticFeedback}> {/* New Friend Button */}
          <img src={MineButton} className="buttonBackground" alt="Mine Button" />
        </Link>
        <Link to="/invite" className="footerButton" onClick={triggerHapticFeedback}> {/* New Friend Button */}
          <img src={FriendButton} className="buttonBackground" alt="Friend Button" />
        </Link>
        <Link to="/earn" className="footerButton" onClick={triggerHapticFeedback}>
          <img src={EarnButton} className="buttonBackground" alt="Earn Button" />
        </Link>
        <Link to="/airdrop" className="footerButton" onClick={triggerHapticFeedback}>
          <img src={AirdropButton} className="buttonBackground" alt="Airdrop Button" />
        </Link>
      </div>
    </div>
  );
};

export default React.memo(Footer);
