// utils/saveAppState.js
import { db } from '../firebase';
import { ref, update } from 'firebase/database';

export const saveAppState = (userId, state) => {
  if (userId && state) {
    update(ref(db, `users/${userId}`), state)
      .catch((error) => {
        console.error('Error saving app state:', error);
      });
  }
};
