import React, { useEffect, useRef, useCallback } from 'react';
import '../styles/SettingsScreen.css';
import CloseButton from '../assets/Close_Button.webp';
import HapticButton from '../assets/Haptic_Button.webp';
import TutorialButtonImage from '../assets/Tutorial_Button.webp'; // Assuming you have this image
import { useCurrency } from '../hooks/useCurrency';
import useHapticFeedback from '../hooks/useHapticFeedback';

const SettingsScreen = ({ onClose, onOpenTutorial }) => {
  const screenRef = useRef(null);
  const { hapticFeedback, toggleHapticFeedback } = useCurrency();
  const triggerHapticFeedback = useHapticFeedback('light');

  const handleClickOutside = useCallback((event) => {
    if (screenRef.current && !screenRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleTutorialClick = () => {
    onOpenTutorial();
    onClose();
  };

  return (
    <div className="settingsScreenOverlay">
      <div className="settingsScreenContainer" ref={screenRef} style={{ opacity: 0, transition: 'opacity 0.5s ease-in-out' }} onLoad={() => screenRef.current.style.opacity = 1}>
        <img src={CloseButton} className="settingsCloseButton" alt="Close" onClick={onClose} />
        <div className="settingsScreenContent">
          <div className="tutorialButtonContainer" onClick={handleTutorialClick}>
            <img src={TutorialButtonImage} className="tutorialButtonImage" alt="Tutorial Button" />
            <div className="tutorialButtonText">Tutorial</div>
          </div>
          <div className="hapticButtonContainer" onClick={() => { toggleHapticFeedback(); triggerHapticFeedback(); }}>
            <img src={HapticButton} className={`hapticButtonImage ${hapticFeedback ? 'on' : 'off'}`} alt="Haptic Button" />
            <div className="hapticButtonText">Haptic Feedback {hapticFeedback ? 'On' : 'Off'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
