import { ref, get, set } from 'firebase/database';
import { db } from '../firebase'; // Adjust this import path to where your Firebase instance is initialized


export const fetchUpgrades = async (userId) => {
  try {
    const upgradesRef = ref(db, `users/${userId}/upgrades`);
    const upgradesSnap = await get(upgradesRef);

    if (upgradesSnap.exists()) {
      return upgradesSnap.val();
    }
    return {};
  } catch (error) {
    console.error('Error fetching upgrades:', error);
    return {};
  }
};

export const saveUpgrade = async (userId, upgradeType, level) => {
  try {
    const upgradeRef = ref(db, `users/${userId}/upgrades/${upgradeType}`);
    await set(upgradeRef, level);
    console.log(`Upgrade ${upgradeType} saved successfully to level ${level}.`);
  } catch (error) {
    console.error('Error saving upgrade:', error);
  }
};