import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { LoadingProvider } from './providers/LoadingProvider';
import { TimeProvider } from './providers/TimeProvider';
import { useLoading } from './contexts/LoadingContext';
import LoadingScreen from './components/LoadingScreen';
import preloadImages from './utils/preloadImages';
import MainLayout from './components/MainLayout';
import { CurrencyProvider } from './providers/CurrencyProvider';
import { DailyRewardsProvider } from './providers/DailyRewardsProvider';
import { EarnProvider } from './providers/EarnProvider';
import { RechargeProvider } from './providers/RechargeProvider';
import { UpgradeProvider } from './providers/UpgradeProvider';

// Lazy-loaded components
const HomeScreen = lazy(() => import('./components/HomeScreen'));
const BoostScreen = lazy(() => import('./components/BoostScreen'));
const AirdropScreen = lazy(() => import('./components/AirdropScreen'));
const EarnScreen = lazy(() => import('./components/EarnScreen'));
const InviteScreen = lazy(() => import('./components/InviteScreen'));
const InvestScreen = lazy(() => import('./components/InvestScreen'));

function FadeIn({ children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`fade-in ${isVisible ? 'visible' : ''}`}>
      {children}
    </div>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useLoading();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.expand();

      const version = window.Telegram.WebApp.version;
      if (version && parseFloat(version) >= 6.1) {
        if (location.pathname !== '/') {
          window.Telegram.WebApp.BackButton.show();
          window.Telegram.WebApp.BackButton.onClick(() => {
            navigate('/');
          });
        } else {
          window.Telegram.WebApp.BackButton.hide();
        }

        window.Telegram.WebApp.setHeaderColor('#000000');
      }
    }
  }, [location, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback={<div className="page-loader"></div>}>
      <FadeIn>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/boost" element={<BoostScreen />} />
          <Route path="/airdrop" element={<AirdropScreen />} />
          <Route path="/earn" element={<EarnScreen />} />
          <Route path="/invite" element={<InviteScreen />} />
          <Route path="/invest" element={<InvestScreen />} />
        </Routes>
      </FadeIn>
    </Suspense>
  );
}

function AppProviders({ children, userId }) {
  return (
    <UpgradeProvider userId={userId}>
      <CurrencyProvider userId={userId}>
        <EarnProvider userId={userId}>
          <RechargeProvider userId={userId}>
            <DailyRewardsProvider userId={userId}>
              {children}
            </DailyRewardsProvider>
          </RechargeProvider>
        </EarnProvider>
      </CurrencyProvider>
    </UpgradeProvider>
  );
}

function App() {
  const [userId, setUserId] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        if (window.Telegram && window.Telegram.WebApp) {
          const user = window.Telegram.WebApp.initDataUnsafe.user;
          if (user && user.id) {
            setUserId(user.id.toString());
          }

          // FOR LOCAL TESTING ONLY!
          if (!user?.id && process.env.REACT_APP_TEST_USER_ID) {
            setUserId(process.env.REACT_APP_TEST_USER_ID);
          }
        }

        await preloadImages([
          // Add your image paths here
        ]);
      } catch (error) {
        console.error('Error initializing app:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    initializeApp();
  }, []);

  if (isInitialLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <TimeProvider>
        <LoadingProvider>
          <AppProviders userId={userId}>
            <MainLayout>
              <AppContent />
            </MainLayout>
          </AppProviders>
        </LoadingProvider>
      </TimeProvider>
    </Router>
  );
}

export default App;