import { db } from '../firebase';
import { ref, get, update } from 'firebase/database';

const RECHARGE_COOLDOWN = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
const MAX_RECHARGES = 6;

export const fetchRechargeData = async (userId, getAdjustedTime) => {
  try {
    const userRef = ref(db, `users/${userId}`);
    const userSnap = await get(userRef);
    const serverTime = getAdjustedTime();

    if (userSnap.exists()) {
      const userData = userSnap.val();
      const rechargeData = userData.recharge || {};
      const lastUpdatedR = rechargeData.lastUpdatedR ?? serverTime;
      let nextRechargeTime = rechargeData.nextRechargeTime ?? null;
      let recharges = rechargeData.recharges ?? MAX_RECHARGES;

      const elapsedTime = serverTime - lastUpdatedR;
      const fullRecharges = Math.floor(elapsedTime / RECHARGE_COOLDOWN);

      if (fullRecharges > 0 || recharges < MAX_RECHARGES) {
        recharges = Math.min(recharges + fullRecharges, MAX_RECHARGES);
        
        if (recharges < MAX_RECHARGES) {
          if (nextRechargeTime && nextRechargeTime < serverTime) {
            const additionalRecharges = Math.floor((serverTime - nextRechargeTime) / RECHARGE_COOLDOWN) + 1;
            recharges = Math.min(recharges + additionalRecharges, MAX_RECHARGES);
            nextRechargeTime = nextRechargeTime + (additionalRecharges * RECHARGE_COOLDOWN);
          }
          
          if (!nextRechargeTime || nextRechargeTime < serverTime) {
            nextRechargeTime = serverTime + RECHARGE_COOLDOWN;
          }
        } else {
          nextRechargeTime = null;
        }

        await update(userRef, {
          'recharge/recharges': recharges,
          'recharge/lastUpdatedR': serverTime,
          'recharge/nextRechargeTime': nextRechargeTime
        });
      }

      return { 
        recharges, 
        lastUpdatedR: serverTime,
        nextRechargeTime
      };
    }
  } catch (error) {
    console.error('Error fetching recharge data:', error);
  }

  return {
    recharges: MAX_RECHARGES,
    lastUpdatedR: getAdjustedTime(),
    nextRechargeTime: null
  };
};

export const saveRechargeData = async (userId, rechargeState) => {
  if (userId) {
    try {
      const updates = {};
      for (const [key, value] of Object.entries(rechargeState)) {
        if (['recharges', 'lastUpdatedR', 'nextRechargeTime'].includes(key)) {
          updates[`recharge/${key}`] = value;
        }
      }

      const userRef = ref(db, `users/${userId}`);
      await update(userRef, updates);
      console.log("Recharge data saved successfully.");
    } catch (error) {
      console.error('Error saving recharge data:', error);
    }
  }
};