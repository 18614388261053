import React, { useCallback, useEffect, useState } from 'react';
import { fetchRechargeData, saveRechargeData } from '../dataFetchers/rechargeFetcher';
import RechargeContext from '../contexts/RechargeContext';
import { useTime } from '../contexts/TimeContext';
import { useLoading } from '../contexts/LoadingContext';

const RECHARGE_COOLDOWN = 4 * 60 * 60; // 4 hours in seconds
const MAX_RECHARGES = 6;

export const RechargeProvider = ({ children, userId }) => {
  const [recharges, setRecharges] = useState(MAX_RECHARGES);
  const [nextRechargeTime, setNextRechargeTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const { setProviderLoaded } = useLoading();
  const { getAdjustedTime, isInitialized: isTimeInitialized } = useTime();

  const initializeRecharges = useCallback(async () => {
    if (!userId || !isTimeInitialized) return;

    try {
      const rechargeData = await fetchRechargeData(userId, getAdjustedTime);

      if (rechargeData) {
        setRecharges(rechargeData.recharges);
        setNextRechargeTime(rechargeData.nextRechargeTime);
        if (rechargeData.nextRechargeTime) {
          const now = getAdjustedTime();
          setTimeRemaining(Math.max(0, rechargeData.nextRechargeTime - now));
        }
      }
      setIsInitialized(true);
      setProviderLoaded('recharge');
    } catch (error) {
      console.error('Failed to initialize recharges:', error);
    }
  }, [userId, setProviderLoaded, getAdjustedTime, isTimeInitialized]);

  useEffect(() => {
    initializeRecharges();
  }, [initializeRecharges]);

  useEffect(() => {
    if (!isInitialized || recharges >= MAX_RECHARGES) return;

    const interval = setInterval(() => {
      const now = getAdjustedTime();

      if (nextRechargeTime && now >= nextRechargeTime) {
        setRecharges(prev => {
          const newRecharges = Math.min(prev + 1, MAX_RECHARGES);

          if (newRecharges < MAX_RECHARGES) {
            const newNextRechargeTime = now + RECHARGE_COOLDOWN * 1000; // Convert to milliseconds
            setNextRechargeTime(newNextRechargeTime);
            saveRechargeData(userId, {
              recharges: newRecharges,
              nextRechargeTime: newNextRechargeTime,
            });
          } else {
            setNextRechargeTime(null);
            saveRechargeData(userId, {
              recharges: newRecharges,
              nextRechargeTime: null,
            });
          }
          return newRecharges;
        });
      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, [recharges, nextRechargeTime, userId, isInitialized, getAdjustedTime]);

  // New effect to update timeRemaining every second
  useEffect(() => {
    if (!isInitialized || recharges >= MAX_RECHARGES || !nextRechargeTime) return;

    const interval = setInterval(() => {
      const now = getAdjustedTime();
      setTimeRemaining(Math.max(0, nextRechargeTime - now));
    }, 1000);

    return () => clearInterval(interval);
  }, [isInitialized, recharges, nextRechargeTime, getAdjustedTime]);

  const updateRecharges = useCallback(async () => {
    if (recharges <= 0) return;

    const now = getAdjustedTime();
    const newRecharges = recharges - 1;
    setRecharges(newRecharges);

    let newNextRechargeTime = nextRechargeTime;

    if (newRecharges === MAX_RECHARGES - 1) {
      newNextRechargeTime = now + RECHARGE_COOLDOWN * 1000; // Convert to milliseconds
      setNextRechargeTime(newNextRechargeTime);
      setTimeRemaining(RECHARGE_COOLDOWN * 1000);
    }

    await saveRechargeData(userId, {
      recharges: newRecharges,
      nextRechargeTime: newNextRechargeTime,
    });
  }, [recharges, nextRechargeTime, userId, getAdjustedTime]);

  const formatCoolDown = useCallback(() => {
    if (recharges === MAX_RECHARGES) return 'Full';
    if (timeRemaining === null) return '--:--:--';

    const totalSeconds = Math.ceil(timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [recharges, timeRemaining]);

  const contextValue = {
    recharges,
    updateRecharges,
    formatCoolDown,
  };

  return (
    <RechargeContext.Provider value={contextValue}>
      {children}
    </RechargeContext.Provider>
  );
};

export default RechargeProvider;