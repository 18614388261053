import { createContext, useContext } from 'react';

const EarnContext = createContext();

export const useEarn = () => {
  const context = useContext(EarnContext);
  if (!context) {
    throw new Error('useEarn must be used within an EarnProvider');
  }
  return context;
};

export default EarnContext;
