import React, { useState, useEffect, useCallback } from 'react';
import { saveDailyRewardsData, fetchDailyRewardsData } from '../dataFetchers/rewardsFetcher';
import { useCurrency } from '../contexts/CurrencyContext';
import DailyRewardsContext from '../contexts/DailyRewardsContext';
import { useTime } from '../contexts/TimeContext';
import { useLoading } from '../contexts/LoadingContext';

export const rewards = [
  { day: 1, amount: 500, displayAmount: '500' },
  { day: 2, amount: 750, displayAmount: '750' },
  { day: 3, amount: 1150, displayAmount: '1.15k' },
  { day: 4, amount: 1700, displayAmount: '1.70k' },
  { day: 5, amount: 2500, displayAmount: '2.50k' },
  { day: 6, amount: 5000, displayAmount: '5k' },
  { day: 7, amount: 10000, displayAmount: '10k' },
  { day: 8, amount: 20000, displayAmount: '20k' },
  { day: 9, amount: 40000, displayAmount: '40k' },
  { day: 10, amount: 80000, displayAmount: '80k' },
  { day: 11, amount: 160000, displayAmount: '160k' },
  { day: 12, amount: 250000, displayAmount: '250k' },
  { day: 13, amount: 500000, displayAmount: '500k' },
  { day: 14, amount: 1000000, displayAmount: '1m' },
  { day: 15, amount: 5000000, displayAmount: '5m' },
];

export const DailyRewardsProvider = ({ children, userId }) => {
  const [currentDay, setCurrentDay] = useState(1);
  const [lastClaimed, setLastClaimed] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const { addCurrency } = useCurrency();
  const { setProviderLoaded } = useLoading();
  const { getAdjustedTime, isInitialized } = useTime();

  const CLAIM_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const RESET_INTERVAL = 48 * 60 * 60 * 1000; // 48 hours in milliseconds
  const MAX_CYCLE_DAYS = rewards.length;

  useEffect(() => {
    const initializeDailyRewards = async () => {
      if (userId && isInitialized) {
        setIsLoading(true);
        try {
          const { currentDay, lastClaimed } = await fetchDailyRewardsData(userId, getAdjustedTime);
          const serverTime = getAdjustedTime();

          if (lastClaimed && serverTime - lastClaimed >= RESET_INTERVAL) {
            await resetRewards();
          } else {
            setCurrentDay(currentDay);
            setLastClaimed(lastClaimed);
            if (lastClaimed) {
              const remaining = Math.max(0, CLAIM_INTERVAL - (serverTime - lastClaimed));
              setTimeRemaining(remaining);
            }
          }
        } catch (error) {
          console.error('Error initializing daily rewards:', error);
          await resetRewards();
        } finally {
          setIsLoading(false);
          setProviderLoaded('dailyRewards');
        }
      }
    };

    initializeDailyRewards();
  }, [userId, isInitialized, setProviderLoaded, getAdjustedTime]);

  const canClaimReward = useCallback(() => {
    if (isLoading) return false;
    return timeRemaining === 0 || timeRemaining === null;
  }, [isLoading, timeRemaining]);

  const claimReward = useCallback(async () => {
    if (!canClaimReward()) return false;

    const serverTime = getAdjustedTime();
    const nextDay = currentDay >= MAX_CYCLE_DAYS ? 1 : currentDay + 1;
    const rewardAmount = rewards[currentDay - 1].amount;

    addCurrency(rewardAmount);
    setCurrentDay(nextDay);
    setLastClaimed(serverTime);
    setTimeRemaining(CLAIM_INTERVAL); // Reset the timer

    try {
      await saveDailyRewardsData(userId, {
        currentDay: nextDay,
        lastClaimed: serverTime
      }, getAdjustedTime);
      return true;
    } catch (error) {
      console.error('Error saving claim data:', error);
      return false;
    }
  }, [currentDay, addCurrency, userId, canClaimReward, getAdjustedTime]);

  const resetRewards = useCallback(async () => {
    setCurrentDay(1);
    setLastClaimed(null);
    setTimeRemaining(null);

    try {
      await saveDailyRewardsData(userId, {
        currentDay: 1,
        lastClaimed: null
      }, getAdjustedTime);
    } catch (error) {
      console.error('Error resetting rewards:', error);
    }
  }, [userId, getAdjustedTime]);

  useEffect(() => {
    const checkResetInterval = async () => {
      if (lastClaimed) {
        const serverTime = getAdjustedTime();
        if (serverTime - lastClaimed >= RESET_INTERVAL) {
          await resetRewards();
        }
      }
    };

    const interval = setInterval(checkResetInterval, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [lastClaimed, resetRewards, getAdjustedTime]);

  useEffect(() => {
    let interval;
    if (lastClaimed) {
      interval = setInterval(() => {
        const serverTime = getAdjustedTime();
        const remaining = Math.max(0, CLAIM_INTERVAL - (serverTime - lastClaimed));
        setTimeRemaining(remaining);

        if (remaining === 0) {
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [lastClaimed, CLAIM_INTERVAL, getAdjustedTime]);

  const formatCoolDown = useCallback(() => {
    if (timeRemaining === null || timeRemaining === 0) return 'Claim Now!';
    
    const totalSeconds = Math.ceil(timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [timeRemaining]);

  const contextValue = {
    currentDay,
    claimReward,
    lastClaimed,
    canClaimReward,
    isLoading,
    rewards,
    formatCoolDown,
    timeRemaining
  };

  return (
    <DailyRewardsContext.Provider value={contextValue}>
      {children}
    </DailyRewardsContext.Provider>
  );
};
