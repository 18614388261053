import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import CurrencyContext from '../contexts/CurrencyContext';
import { fetchUserData, saveButtonStateToDB, saveReferredUserIncome } from '../services/firebaseService';
import { ref, get } from 'firebase/database';
import { calculateTotalEarningsPerHour } from '../utils/upgradeCalculator';
import { db } from '../firebase';
import axios from 'axios';
import { useLoading } from '../contexts/LoadingContext';
import { useUpgrade } from '../contexts/UpgradeContext';
import { useTime } from '../contexts/TimeContext';

export const CurrencyProvider = ({ children, userId }) => {
  const [currency, setCurrency] = useState(0);
  const [energy, setEnergy] = useState(1000);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [clickedButtons, setClickedButtons] = useState([]);
  const [multitapLevel, setMultitapLevel] = useState(1);
  const [maxEnergyLevel, setMaxEnergyLevel] = useState(1);
  const [hasSeenTutorial, setHasSeenTutorial] = useState(false);
  const [offlineEarnings, setOfflineEarnings] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [invitedFriends, setInvitedFriends] = useState(false);
  const [joinedTelegram, setJoinedTelegram] = useState(false);
  const [referredUser, setReferredUser] = useState(0);
  const [timeUntilNextReset, setTimeUntilNextReset] = useState('');
  const [lastPassiveIncomeUpdate, setLastPassiveIncomeUpdate] = useState(null);
  const [totalPassiveIncomeGenerated, setTotalPassiveIncomeGenerated] = useState(0);
  const [lastEnergyRegenUpdate, setLastEnergyRegenUpdate] = useState(null);
  const [fractionalCurrency, setFractionalCurrency] = useState(0);
  const [upgrades, setUpgrades] = useState({});
  const [totalEarningsPerHour, setTotalEarningsPerHour] = useState(0);
  const { upgradeLevels } = useUpgrade();
  const [user, setUser] = useState({
    first_name: 'User',
  });
  const [hapticFeedback, setHapticFeedback] = useState(true);

  const { setProviderLoaded } = useLoading();
  const { getAdjustedTime, isInitialized: isTimeInitialized } = useTime();

  const levelRequirements = useMemo(() => [10000, 50000, 150000, 500000, 1000000, 5000000, 15000000, 100000000], []);

  const getMaxCurrencyForNextLevel = useCallback((level) => {
    const safeLevel = Math.max(1, Math.min(level, levelRequirements.length + 1));
    if (safeLevel > levelRequirements.length) {
      return Infinity;
    }
    return levelRequirements[safeLevel - 1];
  }, [levelRequirements]);

  const earnPerTap = useMemo(() => Math.floor(1 + (currentLevel - 1) + (multitapLevel - 1)), [multitapLevel, currentLevel]);

  const maxEnergy = useMemo(() => Math.floor(1000 + ((currentLevel - 1) * 500) + ((maxEnergyLevel - 1) * 500)), [maxEnergyLevel, currentLevel]);

  const recalculateTotalEarnings = useCallback(() => {
    const newTotalEarnings = calculateTotalEarningsPerHour(upgrades, currentLevel);
    setTotalEarningsPerHour(newTotalEarnings);
  }, [upgrades, currentLevel]);

  useEffect(() => {
    setUpgrades(upgradeLevels);
  }, [upgradeLevels]);

  useEffect(() => {
    recalculateTotalEarnings();
  }, [upgrades, recalculateTotalEarnings]);

  useEffect(() => {
    if (userId && isTimeInitialized) {
      const fetchData = async () => {
        const serverTime = getAdjustedTime();
        const safeSetCurrentLevel = (newLevel) => {
          setCurrentLevel(prevLevel => Math.max(prevLevel, newLevel));
        };
        console.log('Before fetching user data:', { userId, serverTime });
  
        const fetchedData = await fetchUserData(
          userId,
          setUser,
          setCurrency,
          setEnergy,
          safeSetCurrentLevel,
          setClickedButtons,
          setMultitapLevel,
          setMaxEnergyLevel,
          setLastUpdated,
          setHapticFeedback,
          setJoinedTelegram,
          setOfflineEarnings,
          setHasSeenTutorial,
          setInvitedFriends,
          setReferredUser,
          setTotalEarningsPerHour,
          getAdjustedTime // Pass getAdjustedTime to fetchUserData
        );
  
        if (fetchedData) {
          console.log('Fetched data:', fetchedData);
          setUpgrades(fetchedData.upgrades || {});
          setTotalEarningsPerHour(fetchedData.stats?.totalEarningsPerHour || 0);
        }
  
        setLastUpdated(serverTime);
        setProviderLoaded('currency');
  
        console.log('After setting data in context:', { serverTime });
      };
  
      fetchData();
    }
  }, [userId, setProviderLoaded, getAdjustedTime, isTimeInitialized]);
  

  const calculatePassiveIncome = useCallback(() => {
    if (lastPassiveIncomeUpdate === null) return;
  
    const now = getAdjustedTime();
    const timeDifferenceInSeconds = Math.floor((now - lastPassiveIncomeUpdate) / 1000);
    const earningsPerSecond = totalEarningsPerHour / 3600;
    const totalPassiveIncome = timeDifferenceInSeconds * earningsPerSecond;
  
    const wholeIncome = Math.floor(totalPassiveIncome);
    const fractionalIncome = totalPassiveIncome - wholeIncome;
  
    setCurrency(prevCurrency => prevCurrency + wholeIncome);
    setFractionalCurrency(prevFractional => {
      const newFractional = prevFractional + fractionalIncome;
      if (newFractional >= 1) {
        const wholeFromFraction = Math.floor(newFractional);
        setCurrency(prevCurrency => prevCurrency + wholeFromFraction);
        return newFractional - wholeFromFraction;
      }
      return newFractional;
    });
    setTotalPassiveIncomeGenerated(prev => prev + wholeIncome);
    setLastPassiveIncomeUpdate(now);
  }, [totalEarningsPerHour, lastPassiveIncomeUpdate, setCurrency, getAdjustedTime]);
  
  const calculateEnergyRegen = useCallback(() => {
    if (lastEnergyRegenUpdate === null) return;
  
    const now = getAdjustedTime();
    const timeDifferenceInSeconds = Math.floor((now - lastEnergyRegenUpdate) / 1000);
    const energyRegenRate = 3;
    const regeneratedEnergy = Math.min(timeDifferenceInSeconds * energyRegenRate, maxEnergy - energy);
    setEnergy(prevEnergy => Math.min(prevEnergy + regeneratedEnergy, maxEnergy));
    setLastEnergyRegenUpdate(now);
  }, [lastEnergyRegenUpdate, maxEnergy, energy, setEnergy, getAdjustedTime]);
  
  useEffect(() => {
    const initializeTimestamps = () => {
      const serverTime = getAdjustedTime();
      setLastPassiveIncomeUpdate(serverTime);
      setLastEnergyRegenUpdate(serverTime);
    };
    initializeTimestamps();
  }, [getAdjustedTime]);
  
  useEffect(() => {
    const updatePassiveIncome = () => {
      calculatePassiveIncome();
    };
  
    const interval = setInterval(updatePassiveIncome, 1000);
    return () => clearInterval(interval);
  }, [calculatePassiveIncome]);
  
  useEffect(() => {
    const updateEnergyRegen = () => {
      calculateEnergyRegen();
    };
  
    const interval = setInterval(updateEnergyRegen, 1000);
    return () => clearInterval(interval);
  }, [calculateEnergyRegen]);

  const saveButtonState = useCallback((newState, immediate = false) => {
    console.log(`Saving button state: ${JSON.stringify(newState)}`);
    saveButtonStateToDB(userId, newState, getAdjustedTime, immediate).catch(error => {
      console.error('Error saving button state:', error);
    });
  }, [userId, getAdjustedTime]);

  const setTutorialSeen = useCallback(async() => {
    const serverTime = getAdjustedTime();
    setHasSeenTutorial(true);
    saveButtonState({ hasSeenTutorial: true, lastUpdated: serverTime }, true);
  }, [saveButtonState, getAdjustedTime]);

  const checkAndUpdateLevel = useCallback(async () => {
    let newLevel = currentLevel;
  
    while (newLevel < levelRequirements.length + 1 && currency >= getMaxCurrencyForNextLevel(newLevel)) {
      newLevel++;
    }
  
    if (newLevel > currentLevel) {  // Only update if the new level is higher
      setCurrentLevel(newLevel);
      const serverTime = getAdjustedTime();
      saveButtonState({
        currency,
        currentLevel: newLevel,
        energy,
        lastUpdated: serverTime,
      }, true);
    }
  }, [currentLevel, currency, getMaxCurrencyForNextLevel, levelRequirements, energy, saveButtonState, getAdjustedTime]);
  
  useEffect(() => {
    checkAndUpdateLevel();
  }, [currency, checkAndUpdateLevel]);
  
  const addCurrency = useCallback(async (amount) => {
    const now = getAdjustedTime();
    setCurrency(prevCurrency => {
      const newCurrency = prevCurrency + amount;
      
      const updates = {
        'stats/currency': newCurrency,
        'stats/lastUpdated': now,
      };
  
      saveButtonStateToDB(userId, updates, getAdjustedTime, true);
      
      if (user.referral?.referrerId) {
        const totalIncrease = amount + totalPassiveIncomeGenerated;
        saveReferredUserIncome(userId, totalIncrease);
      }
  
      setLastUpdated(now);
      setTotalPassiveIncomeGenerated(0);
      return newCurrency;
    });
  }, [userId, user.referral?.referrerId, totalPassiveIncomeGenerated, getAdjustedTime]);
  
  const subtractCurrency = useCallback((amount) => {
    const serverTime = getAdjustedTime();
    setCurrency(prevCurrency => {
      const newCurrency = Math.floor(prevCurrency - amount);
      saveButtonState({ currency: newCurrency, lastUpdated: serverTime }, true);
      return newCurrency;
    });
  }, [saveButtonState, getAdjustedTime]);

  const addEnergy = useCallback((amount) => {
    setEnergy(prevEnergy => {
      const newEnergy = Math.min(prevEnergy + amount, maxEnergy);
      saveButtonState({ energy: newEnergy }, true);
      return newEnergy;
    });
  }, [maxEnergy, saveButtonState]);

  const subtractEnergy = useCallback((amount) => {
    const serverTime = getAdjustedTime();
    setEnergy(prevEnergy => {
      const newEnergy = Math.max(prevEnergy - amount, 0); // Ensures energy does not go below 0

      // Save the updated energy state to the database
      saveButtonState({
        energy: newEnergy,
        lastUpdated: serverTime,
      }, true);

      return newEnergy;
    });
  }, [saveButtonState, getAdjustedTime]);

  const handleButtonClick = useCallback((buttonId) => {
    const serverTime = getAdjustedTime();
    setClickedButtons(prevClickedButtons => {
      const updatedClickedButtons = [...prevClickedButtons, buttonId];
      saveButtonState({ clickedButtons: updatedClickedButtons, lastUpdated: serverTime }, true);
      return updatedClickedButtons;
    });
  }, [saveButtonState, getAdjustedTime]);

  const updateMultitapLevel = useCallback((level) => {
    const serverTime = getAdjustedTime();
    setMultitapLevel(level);
    saveButtonState({ multitapLevel: level, lastUpdated: serverTime }, true);
  }, [saveButtonState, getAdjustedTime]);

  const updateMaxEnergyLevel = useCallback((level) => {
    const serverTime = getAdjustedTime();
    setMaxEnergyLevel(level);
    saveButtonState({ maxEnergyLevel: level, lastUpdated: serverTime }, true);
  }, [saveButtonState, getAdjustedTime]);

  const currentMultitapPrice = useMemo(() => {
    return 1000 * Math.pow(2, multitapLevel - 1);
  }, [multitapLevel]);

  const currentMaxEnergyPrice = useMemo(() => {
    return 1000 * Math.pow(2, maxEnergyLevel - 1);
  }, [maxEnergyLevel]);

  const toggleHapticFeedback = useCallback(() => {
    setHapticFeedback(prevState => {
      const newState = !prevState;
      saveButtonState({ hapticFeedback: newState }, true);
      return newState;
    });
  }, [saveButtonState]);

  const handleJoinTelegram = useCallback(() => {
    window.open('https://t.me/mrbeist6000', '_blank');
  }, []);

  const handleVerifyTelegram = useCallback(async () => {
    if (!joinedTelegram) {
      try {
        const response = await axios.post('/api/telegramController', { userId });
        const { success } = response.data;
        if (success) {
          setJoinedTelegram(true);
          const serverTime = getAdjustedTime();
          addCurrency(2500);
          saveButtonState({ joinedTelegram: true, currency: currency + 2500, lastUpdated: serverTime }, true);
          return true;
        }
      } catch (error) {
        console.error('Error verifying Telegram group membership:', error);
      }
    }
    return false;
  }, [joinedTelegram, addCurrency, currency, saveButtonState, userId, getAdjustedTime]);

  const handleVerifyFriends = useCallback(async () => {
    if (referredUser >= 3) {
      setInvitedFriends(true);
      const serverTime = getAdjustedTime();
      addCurrency(20000);
      saveButtonState({ invitedFriends: true, currency: currency + 20000, lastUpdated: serverTime }, true);
      return true;
    }
    return false;
  }, [referredUser, saveButtonState, addCurrency, currency, getAdjustedTime]);

  const generateInviteLink = useCallback(async () => {
    if (userId) {
      const userRef = ref(db, `users/${userId}/referral`);
      const userSnap = await get(userRef);
      const userData = userSnap.val();

      if (userData && userData.inviteToken) {
        const inviteToken = userData.inviteToken;
        const inviteLink = `https://t.me/BeistMoneybot?start=${inviteToken}`;
        return inviteLink;
      }
    }
    return '';
  }, [userId]);

  const contextValue = useMemo(() => ({
    currency,
    setCurrency,
    addCurrency,
    subtractCurrency,
    energy,
    lastPassiveIncomeUpdate,
    lastEnergyRegenUpdate,
    setLastPassiveIncomeUpdate,
    setLastEnergyRegenUpdate,
    setEnergy,
    addEnergy,
    subtractEnergy,
    getMaxCurrencyForNextLevel,
    currentLevel,
    clickedButtons,
    handleButtonClick,
    earnPerTap,
    maxEnergy,
    multitapLevel,
    updateMultitapLevel,
    maxEnergyLevel,
    updateMaxEnergyLevel,
    saveButtonState,
    currentMultitapPrice,
    currentMaxEnergyPrice,
    user,
    hapticFeedback,
    toggleHapticFeedback,
    handleJoinTelegram,
    handleVerifyTelegram,
    joinedTelegram,
    generateInviteLink,
    lastUpdated,
    totalEarningsPerHour,
    setTotalEarningsPerHour,
    upgrades,
    offlineEarnings,
    setOfflineEarnings,
    setLastUpdated,
    hasSeenTutorial,
    setTutorialSeen,
    invitedFriends,
    handleVerifyFriends,
    referredUser,
    setInvitedFriends,
    timeUntilNextReset
  }), [
    currency,
    setCurrency,
    energy,
    lastPassiveIncomeUpdate,
    lastEnergyRegenUpdate,
    setEnergy,
    setTotalEarningsPerHour,
    totalEarningsPerHour,
    getMaxCurrencyForNextLevel,
    currentLevel,
    clickedButtons,
    earnPerTap,
    addCurrency,
    subtractCurrency,
    addEnergy,
    subtractEnergy,
    handleButtonClick,
    maxEnergy,
    multitapLevel,
    updateMultitapLevel,
    maxEnergyLevel,
    updateMaxEnergyLevel,
    saveButtonState,
    currentMultitapPrice,
    currentMaxEnergyPrice,
    user,
    hapticFeedback,
    toggleHapticFeedback,
    handleJoinTelegram,
    handleVerifyTelegram,
    joinedTelegram,
    generateInviteLink,
    lastUpdated,
    totalEarningsPerHour,
    upgrades,
    offlineEarnings,
    setOfflineEarnings,
    setLastUpdated,
    hasSeenTutorial,
    setTutorialSeen,
    invitedFriends,
    handleVerifyFriends,
    referredUser,
    setInvitedFriends,
    timeUntilNextReset,
  ]);

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
