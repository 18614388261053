import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SettingsButton from '../assets/Settings_Button.webp';
import WalletButton from '../assets/Wallet_Button.webp';
import TopLogo from '../assets/Logo.webp';
import HeaderBar from '../assets/Header_Bar.webp';
import '../styles/Header.css';
import { useCurrency } from '../contexts/CurrencyContext';
import SettingsScreen from './SettingsScreen';
import WalletScreen from './WalletScreen';
import TutorialScreen from './TutorialScreen';
import useHapticFeedback from '../hooks/useHapticFeedback';

const Header = React.memo(() => {
  const { user, loading } = useCurrency();
  const [showSettingsScreen, setShowSettingsScreen] = useState(false);
  const [showWalletScreen, setShowWalletScreen] = useState(false);
  const [showTutorialScreen, setShowTutorialScreen] = useState(false);
  const triggerHapticFeedback = useHapticFeedback('light');

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []);

  const truncatedName = useMemo(() => {
    if (user && user.profile?.first_name) {
      return user.profile.first_name.length > 7 
        ? user.profile.first_name.substring(0, 6) + '...' 
        : user.profile.first_name;
    }
    return 'User';
  }, [user]);

  const handleSettingsClick = useCallback(() => {
    setShowSettingsScreen(true);
    triggerHapticFeedback();
  }, [triggerHapticFeedback]);

  const handleCloseSettings = useCallback(() => {
    setShowSettingsScreen(false);
  }, []);

  const handleOpenTutorial = useCallback(() => {
    setShowTutorialScreen(true);
  }, []);

  const handleCloseTutorial = useCallback(() => {
    setShowTutorialScreen(false);
  }, []);

  const handleCloseWallet = useCallback(() => {
    setShowWalletScreen(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div>
      <img src={HeaderBar} className="headerBar" alt="Header Bar" />
      <div className="headerButtonsContainer">
        <div className="profileButtonContainer">
          <span className="profileButtonText">{'Mr' + truncatedName}</span>
        </div>
        <img src={TopLogo} className="topLogo" alt="Top Logo" />
        <div className="rightButtonsContainer">
          <img 
            src={SettingsButton} 
            className="settingsButton" 
            alt="Settings Button" 
            onClick={handleSettingsClick}
          />
        </div>
      </div>
      {showSettingsScreen && (
        <SettingsScreen onClose={handleCloseSettings} onOpenTutorial={handleOpenTutorial} />
      )}
      {showWalletScreen && (
        <WalletScreen onClose={handleCloseWallet} />
      )}
      {showTutorialScreen && (
        <TutorialScreen onClose={handleCloseTutorial} />
      )}
    </div>
  );
});

export default Header;